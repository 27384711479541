import React, {useEffect, useState} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import DatePicker from "react-datepicker";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Config from "../../core/config";
import {adjustDateToBrowserTimeZone, getOnlyDate} from "../../utils/utils";
import SupplierSelect from "../supplier/SupplierSelect";
import {getSupplierById} from "../../utils/requestUtils";
import SearchButton from "../../core/SearchButton";
import BackButton from "../common/BackButton";
import ConfirmButton from "../common/ConfirmButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;

const PaymentManager = (props) => {
    const {id, onSave, onBack} = props;
    const [, setLoading] = useRecoilState(loading);
    const [showSupplierSelect, setShowSupplierSelect] = useState(false);

    const newEntity = {
        id: undefined,
        supplier: undefined,
        description: '',
        value: 0.0,
        date: getOnlyDate(new Date()),
        dueDate: getOnlyDate(new Date()),
        paymentDate: undefined,
        status: 0,
    };
    const [formData, setFormData] = useState(newEntity);

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };
    const handleBirthDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['birthDate']: date
        }));
    };

    const handleKidBirthDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['kidBirthDate']: date
        }));
    };

    async function makeQuery() {
        if (id) {
            try {
                const url = apiUrl + '/api/payments/id=' + id;
                const response = await makeRequest(url, 'GET', {'Useruuid':Cookies.get('userUUID')});
                setFormData(response);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFormData(newEntity);
        }
    }

    useEffect(() => {
        makeQuery();
    }, [id]);

    function clearEntity() {
        setFormData(newEntity);
    }

    const onConfirm = async () => {

        try {
            if (validateInputs()) {
                setLoading(true);
                await new Promise(resolve => setTimeout(resolve, 300));
                await saveEntity();
                onSave();
            }
        } catch (error) {
            console.error('Error saving:', error);
            alert('Error ao salvar.');
        } finally {
            setLoading(false);
        }
    };

    function validateInputs() {

        if (!formData.supplier) {
            alert('Preencha o campo fornecedor.');
            return false;
        }

        if (!formData.description || formData.description === '') {
            alert('Preencha o campo descrição.');
            return false;
        }


        if (!formData.value || formData.value === 0) {
            alert('Preencha o campo valor.');
            return false;
        }

        if (!formData.date || formData.date === '') {
            alert('Preencha o campo data.');
            return false;
        }

        if (!formData.dueDate || formData.dueDate === '') {
            alert('Preencha o campo data de vencimento.');
            return false;
        }

        return true;
    }

    async function saveEntity() {
        const url = apiUrl + '/api/payments';
        await makeRequest(url, id ? 'PUT' : 'POST', {
            'Content-Type': 'application/json',
            'Useruuid':Cookies.get('userUUID')
        }, JSON.stringify(formData));
    }

    async function selectSupplier(id) {
        const customer = await getSupplierById(id);
        setFormData((prevData) => ({
            ...prevData,
            supplier: customer
        }));
    }

    const handleSupplierItemClick = async (id) => {
        await selectSupplier(id);
        closeSupplierSelect();

    };


    function handleOnClickSupplier() {
        openSupplierSelect();
    }

    const openSupplierSelect = () => {
        setShowSupplierSelect(true);
    };

    const closeSupplierSelect = () => {
        setShowSupplierSelect(false);
    };

    const handleDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['date']: date
        }));
    };

    const handleDueDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['dueDate']: date
        }));
    };
    const handlePaymentDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            ['paymentDate']: date
        }));
    };


    return (
        <div>
            {showSupplierSelect && (
                <SupplierSelect onItemClick={handleSupplierItemClick} onClose={closeSupplierSelect}/>)}
            <div className="header-label">{formData.id > 0 ? 'Editar' : 'Cadastrar'} Pagamento</div>

            {formData.id && (
                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{width: '180px'}}>Código:</div>
                    <input type="text" style={{backgroundColor: '#CCCCCC'}} value={formData.id} disabled={true}/>
                </div>)}

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Fornecedor:</div>
                <SearchButton onClick={handleOnClickSupplier}/>
                <input type="text" disabled={true} value={formData.supplier ? formData.supplier.name : ''}
                       style={{flexGrow: 1}}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Descrição:</div>
                <input type="text" style={{width: '300px'}} value={formData.description} name="description"
                       onChange={handleChange}/>
            </div>


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Valor:</div>
                <input type="number" style={{width: '100px'}} step="0.01" placeholder="0.00"
                       value={formData.value}
                       name="value"
                       onChange={handleChange}/>
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Data de Lançamento:</div>
                <DatePicker
                    className="datepicker"
                    dateFormat="dd/MM/yyyy"
                    selected={adjustDateToBrowserTimeZone(new Date(formData.date))}
                    onChange={handleDateChange}
                    isClearable
                />
            </div>

            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Data de Vencimento:</div>
                <DatePicker
                    className="datepicker"
                    dateFormat="dd/MM/yyyy"
                    selected={adjustDateToBrowserTimeZone(new Date(formData.dueDate))}
                    onChange={handleDueDateChange}
                    isClearable
                />
            </div>


            <div style={{marginTop: '5px', alignItems: 'center'}}>
                <div style={{width: '180px'}}>Data de Pagamento:</div>
                <DatePicker
                    className="datepicker"
                    dateFormat="dd/MM/yyyy"
                    selected={formData.paymentDate ? adjustDateToBrowserTimeZone(new Date(formData.paymentDate)) : undefined}
                    onChange={handlePaymentDateChange}
                    isClearable
                />
            </div>

            <ActionButtonsManagerView>
                <BackButton onClick={onBack}/>
                <ConfirmButton onClick={onConfirm}/>
            </ActionButtonsManagerView>


        </div>
    );
};

export default PaymentManager;
