import React, {useState, useEffect, useRef} from 'react';
import {makeRequest} from "../../utils/httpRequest";
import ProductSelect from "./ProductSelect";
import ProductAvailability from "./ProductAvailability";
import {findProductsByQueryString} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loading} from "../../atoms";
import Cookies from "js-cookie";

const apiUrl = Config.apiUrl;
const ProductList = (props) => {
        const {onEditButtonClick, onRequestRentProduct, onNewButtonClick} = props;
        let [products, setProducts] = useState([]);
        const [queryString, setQueryString] = useState('');
        const [showProductSelect, setShowProductSelect] = useState(false);
        const [productAvailability, setProductAvailability] = useState(undefined);
        const inputQueryString = useRef(null);
        const [, setLoading] = useRecoilState(loading);
        const [stockQuantityValue, setStockQuantityValue] = useState({quantity: 0, value: 0});

        async function makeQuery(query) {
            try {
                setLoading(true);
                setProducts(await findProductsByQueryString(query));
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }

        useEffect(() => {
            if (inputQueryString.current) {
                inputQueryString.current.focus();
            }
            makeQuery('');
        }, []);

        useEffect(() => {
            setStockQuantityValue({
                quantity: products.reduce((acc, obj) => acc + obj.stock, 0),
                value: products.reduce((acc, obj) => acc + (obj.costValue * obj.stock), 0)
            });
        }, [products]);

        async function excluirProduto(id) {
            try {
                const url = apiUrl + '/api/products/' + id;
                await makeRequest(url, 'DELETE', {'Useruuid':Cookies.get('userUUID')});
                await makeQuery(queryString);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        const handleInputChange = (e) => {
            const string = e.target.value;
            if (string.length >= 3 || string.length === 0) {
                makeQuery(string);
            }
            setQueryString(string);
        };

        const handleQuery = async (e) => {
            await makeQuery(queryString);
        };


        const onAvailabilityClick = (product) => {
            setProductAvailability(product);
            setShowProductSelect(true);
        };

        const closeProductSelect = () => {
            setShowProductSelect(false);
        };


        return (
            <>
                <div className="header-label">Consulta de Produtos</div>
                {showProductSelect && (
                    <ProductAvailability onClose={closeProductSelect} product={productAvailability}
                                         onRequestRentProduct={onRequestRentProduct}/>)}

                <div
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                    <input ref={inputQueryString} type="text" value={queryString} onChange={handleInputChange}/>
                    <button onClick={onNewButtonClick}>Novo</button>
                </div>
                <table style={{width: '700px', height: '453px'}}>
                    <tr>
                        <th>
                            Código
                        </th>
                        <th>
                            Nome
                        </th>
                        <th>
                            Estoque
                        </th>
                        <th>
                            Custo
                        </th>
                        <th/>
                        <th/>
                        <th/>
                    </tr>
                    <tbody style={{overflowY: 'auto'}}>
                    {products.map((product, index) => (


                        <tr>
                            <td>
                                <div key={index}>{product.code}</div>
                            </td>
                            <td>
                                <div key={index}>{product.name}</div>
                            </td>
                            <td>
                                <div key={index}>{product.stock}</div>
                            </td>
                            <td>
                                <div style={{textAlign: 'right'}}>{product.costValue.toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                                </div>
                            </td>
                            <td>

                                <button onClick={async () => {
                                    await onAvailabilityClick(product);
                                }}>Disponibilidade
                                </button>

                            </td>
                            <td>
                                <button onClick={async () => {
                                    await onEditButtonClick(product.id);
                                }}>Editar
                                </button>
                            </td>
                            <td>
                                <button className="remove-button" onClick={async () => {
                                    // eslint-disable-next-line no-restricted-globals
                                    if (window.confirm('Deseja realmente excluir?')) {
                                        await excluirProduto(product.id);
                                    }
                                }}>Excluir
                                </button>
                            </td>

                        </tr>
                    ))}
                    </tbody>
                    {products.length === 0 && (<tfoot>Nenhum item encontrado.</tfoot>)}
                </table>
                {products.length > 0 &&
                    <>
                        <div className="total-label">
                            Quantidade: {stockQuantityValue.quantity}
                        </div>
                        <div className="total-label">Valor Total: R$ {stockQuantityValue.value.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                        </div>
                    </>
                }
            </>
        )
            ;
    }
;

export default ProductList;
