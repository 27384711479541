import React from 'react';
import {BrowserRouter, Route, Router, Routes} from "react-router-dom";

import {useRecoilState} from "recoil";
import Login from "./Login";
import Loading from "./core/Loading";
import {loading} from "./atoms";
import App from "./App";
import Config from "./core/config";
import CustomerAutoFillingCentralizedDiv from "./customerAutoFilling/CustomerAutoFillingCentralizedDiv";
import CustomerAutoFilling_Name from "./customerAutoFilling/CustomerAutoFilling_Name";
import CustomerAutoFilling_Documents from "./customerAutoFilling/CustomerAutoFilling_Documents";
import CustomerAutoFilling_Address from "./customerAutoFilling/CustomerAutoFilling_Address";
import CustomerAutoFilling_Contact from "./customerAutoFilling/CustomerAutoFilling_Contact";
import CustomerAutoFilling_Kids from "./customerAutoFilling/CustomerAutoFilling_Kids";
import CustomerAutoFilling_About from "./customerAutoFilling/CustomerAutoFilling_About";
import CustomerAutoFilling_End from "./customerAutoFilling/CustomerAutoFilling_End";
import CustomerAutoFilling_InvalidLink from "./customerAutoFilling/CustomerAutoFilling_InvalidLink";

const AppRouter = (props) => {

    const {customerKeyValid} = props;
    const [currentLoading] = useRecoilState(loading);

    return (
        <BrowserRouter>
            {currentLoading && (<Loading/>)}
            <Routes>
                <Route path="/app"
                       element={<App/>}/>
                <Route path="/login"
                       element={<Login/>}/>
                <Route path="/autocadastro/:customerKey"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_Name customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/documentos"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_Documents customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/endereco"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_Address customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/contato"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_Contact customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/crianca/:kidIndex"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_Kids customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/sobre"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_About customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/fim"
                       element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_End customerKeyValid={customerKeyValid}/></CustomerAutoFillingCentralizedDiv>}/>
                <Route path="/autocadastro/linkInvalido" element={<CustomerAutoFillingCentralizedDiv><CustomerAutoFilling_InvalidLink/></CustomerAutoFillingCentralizedDiv>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;

